import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Load the mock data.
import { FILES } from '../mock-files';

@Component({
  selector: 'app-filedata',
  templateUrl: './filedata.component.html',
  styleUrls: ['./filedata.component.scss'],
})
export class FiledataComponent implements OnInit {
  files = FILES;
  file = null;

  id: String;
  private sub: any;

  constructor(private route: ActivatedRoute) {}

  ngOnDestroy() {
    // Don't forget to unsubscribe.
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    // Subscribe to the router.
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];

	  // Loop over the mock data to retrive the file by its database ID.
      for (let file of this.files) {
        if (file._id.$oid == this.id) {
          this.file = file;
        }
      }
    });
  }
}
