import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatalistComponent } from './datalist/datalist.component';
import { FiledataComponent } from './filedata/filedata.component';

const routes: Routes = [
  { path: '', component: DatalistComponent, pathMatch: 'full' },
  { path: 'file/:id', component: FiledataComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
