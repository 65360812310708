import { Component, OnInit } from '@angular/core';
// Load the mock data.
import { FILES } from '../mock-files';

@Component({
  selector: 'app-datalist',
  templateUrl: './datalist.component.html',
  styleUrls: ['./datalist.component.scss'],
})
export class DatalistComponent implements OnInit {
  files = FILES;

  constructor() {}

  ngOnInit(): void {}
}
 